import { Component, Input, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbDropdownConfig, NgbModal, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import { TipoAcordo, TipoAcordoLabel } from 'src/app/enums/Acordo/tipo-acordo.enum';
import { SelectList } from 'src/app/models/select-list';
import { SelectListService } from 'src/app/services/shared/select-list.service';
import { NgbDateCustomParserFormatter } from '../../../../utils/dateformat';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { Acordo } from 'src/app/models/operacionais/acordo/acordo';
import { ProcessoDivida } from 'src/app/models/operacionais/operacao/processo-divida';
import { ProcessosJudiciaisValorService } from 'src/app/services/processos-judiciais-valor.service';
import { AtualizacaoValoresService } from 'src/app/services/atualizacao-valores.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { Guid } from 'guid-typescript';
import { ModalService } from 'src/app/services/shared/modal-service';
import { ResultadoModal } from 'src/app/services/shared/resultado-modal';
import { Subject } from 'rxjs';
import { Parametro } from 'src/app/models/shared/parametro';
import { ParametroService } from 'src/app/services/parametro.service';
import { ParametroChaveType } from 'src/app/enums/parametro/parametro-chave-type.enum';
import { ModalAprovarAcordoJudicialComponent } from './modal-aprovar-acordo-judicial/modal-aprovar-acordo-judicial.component';
import { ModalCadastrarAcordoJudicialComponent } from './modal-cadastrar-acordo-judicial/modal-cadastrar-acordo-judicial.component';
import { ModalParcelasComponent } from '../../gestao-acordos-judiciais/modal-parcelas/modal-parcelas.component';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ModalPagamentoComponent } from '../../gestao-acordos-judiciais/modal-pagamento/modal-pagamento.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { fi } from 'date-fns/locale';

@Component({
    selector: 'app-acordos-processo-judicial',
    templateUrl: './acordos-processo-judicial.component.html',
    styleUrls: ['./acordos-processo-judicial.component.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        NgbDropdownConfig
    ]
})
export class AcordosProcessoJudicialComponent implements OnInit {

    @Input() processoJudicialId: Guid;
    @Input() recursoDesdobramentoId: Guid;
    @Input() pessoaId: Guid;
    @Input() taxasAcordo: any = {};


    modalRef: NgbModalRef;
    resultConfirmDialog$ = new Subject<ResultadoModal>();

    ListTipoAcordo: SelectList[] = [];

    public menuEdivarVisible: Boolean = false;

    public loadingAcordos: Boolean = true;
    public loadAcordosParcelas: Boolean = false;

    public acordos: any[];
    public acordosEmAnalise: any[];
    public acordosVingentes: any[];
    public acordosParcelasPorProcesso: any[];
    public acordosParcelasPorProcessoAgrupado: any[];

    public totaisAcordo: any[];

    tituloTable : string = "";    

    public raiz: string = '';

    constructor(
        config: NgbDropdownConfig,
        private selectListService: SelectListService,
        private translateService: TranslateService,
        private primeNgConfig: PrimeNGConfig,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public acordoJuridicoService: AcordoJuridicoService,
        public modalService: ModalService,
        private ngbModal: NgbModal,
        private parametroService: ParametroService,
        private arquivoService: ArquivoService,
        public utilsService: UtilsService,   
        public modal: NgbModal,     
        private router: Router,
    ) {
        config.placement = 'left-center';
    }

    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }
        this.obterAcordos();
    }

    // checkColorBadgeSituacaoAcordo(acordo) {
    //     var guid = Guid.parse(acordo.situacaoJudicialId);

    //     //aprovado verde
    //     if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_APROVADO].toString())[0].valor) {
    //         return "badge-success";
    //     }
    //     //reprovado vermelho
    //     if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_NEGADO].toString())[0].valor) {
    //         return "badge-danger";
    //     }
    //     //em analise amarelo
    //     if (guid.toString() == this.parametroL.filter(x => x.chave.toString() == ParametroChaveType[ParametroChaveType.SITUACAO_ACORDO_FILHO_ANALISE].toString())[0].valor) {
    //         return "badge-warning";
    //     }

    //     return "badge-primary";
    // }

    obterAcordos() {
        this.loadingAcordos = true;
        this.acordos = [];
        this.acordosEmAnalise = [];
        this.acordosVingentes = [];
        this.acordoJuridicoService.obterAcordosPorRecursoDescobramento(this.recursoDesdobramentoId)
            .subscribe(res => {
                this.loadingAcordos = false;
                this.acordos = res.data;
                const formatoValor = new Intl.NumberFormat('default',{
                  style : 'currency',
                  currency : 'BRL'
                });
                
                this.acordosEmAnalise = [];
                this.acordosVingentes = [];
                var objetosEmAnalise  = res.data.filter(x=>x.situacaoJudicialId == "a08d1715-ffd2-4fd1-95bd-5e1c1ea924e9");
                var acordosVingentes = res.data.filter(x=>x.situacaoJudicialId == "bb816f79-46e0-4ee9-ac0d-a5b275e59008");
                
                this.SetarValores(objetosEmAnalise,formatoValor,true);
                this.SetarValores(acordosVingentes,formatoValor,false);

                this.spinner.hide();
            }, error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Erro ao buscar acordo!', 'Atenção', { timeOut: 10000 });
                }
            });
    }

    SetarValores(acordos,formatoValor,objetoAnalise){
        if(acordos && acordos.length > 0 ){
            
            const referencias = acordos.map(c=>c.referenciaId).filter((value, index, current_value) => current_value.indexOf(value) === index);
            
            referencias.forEach(ref => {
                debugger;
                let id = acordos.find(x => x.referenciaId == ref).id
                let idExternoCabecalho = acordos.find(x => x.referenciaId == ref).idExternoCabecalho

                let tipoAcordoJudicial = '';

                if(acordos.filter(i=>i.referenciaId == ref).map((item) => item.id).length == 1){
                    tipoAcordoJudicial = acordos.find(x => x.referenciaId == ref).tipoAcordoJudicial
                }else{
                    tipoAcordoJudicial = acordos.find(x => x.tipoAcordoJudicial != "Honorário" && x.referenciaId == ref).tipoAcordoJudicial
                }

                let tipoAcordo = 0;

                if(acordos.filter(i=>i.referenciaId == ref).map((item) => item.id).length == 1){

                    if(acordos.filter(x=>x.tipoAcordoJudicial != "Honorário" && x.referenciaId == ref).length){
                        tipoAcordo = 1
                    }else{
                        tipoAcordo = 2
                    }
                }


                let valorTotal = 0;

                if(acordos.find(x => x.tipoAcordoJudicial != "Honorário" && x.referenciaId == ref)){
                    valorTotal = acordos.find(x => x.tipoAcordoJudicial != "Honorário" && x.referenciaId == ref).valorTotal;
                }

                let valorHononario = 0;

                if(acordos.find(x => x.tipoAcordoJudicial == "Honorário" && x.referenciaId == ref)){
                    valorHononario = acordos.find(x => x.tipoAcordoJudicial == "Honorário" && x.referenciaId == ref).valorTotal;
                }

                var dados = {          
                    tipoAcordo: tipoAcordo,  
                    idExternoCabecalho : idExternoCabecalho,
                    id: id,
                    tipoAcordoJudicial : tipoAcordoJudicial,
                    valorTotal : formatoValor.format(valorTotal),
                    valorHononario : formatoValor.format(valorHononario),
                    dataInicial : "",
                    dataFinal : "",
                    situacao : "",
                    acordos : acordos.filter(i=>i.referenciaId == ref).map((item) => item.id),            
                }
                this.obterInicioFim(id,dados,objetoAnalise)
            });            
        }
    }
    obterInicioFim(id,dados,objetoAnalise){      
      this.spinner.show()
      this.acordoJuridicoService.obterPorId(id).subscribe(res=>{
        dados.dataInicial = res.data.dividas && res.data.dividas.length ? res.data.dividas[0].dataVencimento : "";
        dados.dataFinal = res.data.dividas && res.data.dividas.length ? res.data.dividas[res.data.dividas.length - 1].dataVencimento : "";
        dados.situacao = res.data.situacaoAcordoJudicialDivida;
        dados.referenciaId = res.data.referenciaId;
        
        if(objetoAnalise){          
          this.acordosEmAnalise.push(dados);
        }else{
          
          this.acordosVingentes.push(dados);
        }
        this.spinner.hide();
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Erro ao gravar comentário!', 'Atenção', { timeOut: 10000 });
            }
        })
    }

    detalheAcordo(acordo) {
        const modalRef = this.ngbModal.open(ModalAprovarAcordoJudicialComponent
            , {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.acordoId = acordo;
        modalRef.componentInstance.page = 'AprovarAcordo';

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            debugger;
            this.obterAcordos();
        }).catch(error => { });
    }

    deletarAcordo(acordo, simulacao){
        debugger;
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: simulacao ? 'Deseja realmente excluir a simulação?' : 'Deseja realmente excluir o acordo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.acordoJuridicoService.delete(acordo.id)
                    .subscribe(res =>{
                        this.obterAcordos();   
                        this.spinner.hide();                     
                    },err=>{
                        this.spinner.hide();
                        this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                    })
            }
        });
    }

    abrirModalCadastrarAcordo(tipoAcordo:number) {
        const modalRef = this.ngbModal.open(ModalCadastrarAcordoJudicialComponent
            , {
                size: tipoAcordo == 0 ? 'lg' : 'md',
                backdrop: 'static',
                keyboard: false
            });

        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.processoJudicialId = this.processoJudicialId;
        modalRef.componentInstance.recursoDesdobramentoId = this.recursoDesdobramentoId;
        modalRef.componentInstance.taxasAcordo = this.taxasAcordo;
        modalRef.componentInstance.pessoaId = this.pessoaId;
        modalRef.componentInstance.tipoAcordo = tipoAcordo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.obterAcordos();
        }).catch(error => { });
    }

    carregarParcelas(){
        this.loadAcordosParcelas = true;
        this.acordosParcelasPorProcessoAgrupado = [];
        this.acordoJuridicoService.obterAcordosParcelasPorProcesso(this.recursoDesdobramentoId)
            .subscribe(res=> {
                this.loadAcordosParcelas = false;                
                this.acordosParcelasPorProcesso = res.data;
                
                let agrupamento = res.data.reduce((acumulador, current)=>{
                    let key = current.idExterno+"_"+current.tipoAcordoId;
                    
                    if(!acumulador[key]){
                        acumulador[key] = [];
                    }
                    acumulador[key].push(current);
                    return acumulador;
                },{});                
                
                for(const[key, value] of Object.entries(agrupamento))
                {            
                    this.acordosParcelasPorProcessoAgrupado.push(value);
                }

                console.log(this.acordosParcelasPorProcessoAgrupado)

                this.gerarDetalhesPagamento();
            });
    }

    gerarDetalhesPagamento() {
        const acordosTipo = [...new Set( this.acordosParcelasPorProcesso.map(obj => obj.tipoAcordo)) ];

        this.totaisAcordo = [];

        acordosTipo.forEach(element => {            
            const dividas = this.acordosParcelasPorProcesso.filter(a=>a.tipoAcordo == element);

            const resumo = {
                tipoAcordo: element,
                valorPrincipal: dividas[0].valorPrincipal,
                valorPago: dividas.reduce((ac, cr) =>{return ac + cr.valorPago}, 0),
                valorDesconto: dividas.reduce((ac, cr) =>{return ac + cr.valorDesconto}, 0),
                saldo:dividas.reduce((ac, cr) =>{return ac + cr.saldo}, 0)
            }

            this.totaisAcordo.push(resumo);
        });     
        
        console.log(this.totaisAcordo);
    }

    showModalPagamento(acordo){

        this.acordoJuridicoService.obterPorId(acordo.id).subscribe(res=>{
            
                const modalRef = this.modal.open(ModalPagamentoComponent, {
                    size: "lg",
                    backdrop : 'static',
                    keyboard : false
                });

                modalRef.componentInstance.parcela = res.data.dividas.filter(c=>c.id == acordo.idDivida)[0];
                modalRef.componentInstance.acordo = res.data;
                modalRef.componentInstance.modalRef = modalRef;

                modalRef.result
                .then((result) => {
                    this.carregarParcelas();
                });
        })
    }            

    abrirModalPagamentoAcordo(acordo){

        this.acordoJuridicoService.checarParcelaPossuiSuccessFeeParaAtender(acordo.idDivida)
            .subscribe(res=>{
                if(res.data){
                    this.showModalPagamento(acordo);
                }else{
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-padrao btn-lg mr-3',
                            cancelButton: 'btn btn-default btn-lg'
                        },
                        buttonsStyling: false
                    });
            
                    swalWithBootstrapButtons.fire({
                        title: 'Não foram encontrados dados de faturamento do tipo Success Fee para o valor da parcela informada, deseja incluir agora?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {                                 
                            this.acordoJuridicoService.obterChaveProcessoPorParcela(acordo.idDivida)
                                .subscribe(res=>{                                                                                                        
                                    const url = this.raiz + '/processos-judiciais/editar/' + res.data;
                                    
                                    window.open(url, '_blank');                                    

                                }, err=>{
                                    this.toastrService.error('Não foi possível carregar o processo!', 'Atenção', { timeOut: 10000 });
                                    this.spinner.hide();
                                })
                        }
                    });
                }
            },err=>{
                this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
            })



        
    }

    downloadAnexo(acordo){
        this.spinner.show();
        debugger;
        this.arquivoService.downloadArquivo(acordo.arquivoId).subscribe(data=>{
            this.utilsService.renderDownload(data, acordo.arquivoNome as string);
            this.spinner.hide();
        },err=>{
            this.spinner.hide();
            this.toastrService.error('Não foi possível fazer o download!', 'Atenção', { timeOut: 10000 });
        })
    }

    abrirModalAcordo(acordo){
        const modalRef = this.ngbModal.open(ModalParcelasComponent, {
            size: "lg",
            backdrop : 'static',
            keyboard : false
        });
        console.log(acordo);
        modalRef.componentInstance.acordoId = acordo.id;
        modalRef.componentInstance.acordoIdHonorario = acordo.idHonorario;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then((result) => {
            this.carregarParcelas();
        });
    }

    editarAcordo(acordo){                        
        const modalRef = this.ngbModal.open(ModalCadastrarAcordoJudicialComponent
            , {
                size: acordo.tipoAcordo == 0 ? 'lg' : 'md',
                backdrop: 'static',
                keyboard: false
            });
        
        modalRef.componentInstance.modalRef = modalRef;
        modalRef.componentInstance.processoJudicialId = this.processoJudicialId;
        modalRef.componentInstance.recursoDesdobramentoId = this.recursoDesdobramentoId;
        modalRef.componentInstance.taxasAcordo = this.taxasAcordo;
        modalRef.componentInstance.pessoaId = this.pessoaId;
        modalRef.componentInstance.referenciaId = acordo.referenciaId;
        modalRef.componentInstance.isAdd = false;
        modalRef.componentInstance.tipoAcordo = acordo.tipoAcordo;

        modalRef.result.then(result => {
            this.resultConfirmDialog$.next(result);
            this.obterAcordos();
        }).catch(error => { });
    }

    formatarParcela(acordo){
        const parcela = acordo.numeroParcela < 9 ? '0'+ acordo.numeroParcela : acordo.numeroParcela;
        const quantidadeParcelas = acordo.quantidadeParcelas < 9 ? '0'+ acordo.quantidadeParcelas : acordo.quantidadeParcelas;

        return `${parcela}/${quantidadeParcelas}`;
    }

    exportarAcordo(referenciaId){
        this.spinner.show();
        this.acordoJuridicoService.export(referenciaId)
            .subscribe(res=> {
                var datePipe = new DatePipe("en-US");
                FileSaver.saveAs(res, "acordo-" + datePipe.transform(new Date(), 'dd-MM-yyyy') + '.xlsx');
                this.spinner.hide();
            },error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível baixar!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    obterTotalAcordos(acordos: any, campo:string){        
        return acordos.reduce((ac, ad)=>{return ac + ad[campo] },0);
    }
}

