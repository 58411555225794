<div *ngIf="loading">

</div>

<div *ngIf="!loading">
    <!-- Modal template -->
    <div class="modal-header">

        <h5 class="modal-title">
            Cadastrar Acordo
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="parcelas.length == 0 && parcelasHonorario.length == 0">
            <div [ngClass]="(tipoAcordo == 0 ? 'col-6' : 'col-12')" *ngIf="tipoAcordo == 0 || tipoAcordo == 1">
                <div class="borda card">
                    <h3>Minuta de Acordo</h3>                    
                    <div>
                      <div class="form-group" style="width: 100%;">
                        <label class="form-label">Valor confessado</label>
                        <input type="text" style="width: 150px;" currencyMask
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                            [(ngModel)]="camposSimulacao.valorConfessado" />
                        </div>
                        <div class="form-group" style="width: 100%;">
                            <label class="form-label">Tipo de acordo</label>
                            <br />
                            <div class="form-check form-check-inline form-group mt-1">
                                <input mdbRadio name="tipoAcordo" class="form-check-input" type="radio"
                                    [(ngModel)]="camposSimulacao.tipoAcordo" id="acordo_vinculado" value="0" />
                                <label class="form-check-label" for="acordo_vinculado">Acordo vinculado</label>
                            </div>
                            <div class="form-check form-check-inline form-group mt-1">
                                <input mdbRadio name="tipoAcordo" class="form-check-input" type="radio"
                                    [(ngModel)]="camposSimulacao.tipoAcordo" id="acordo_avulso" value="1"
                                    (change)="limparAcordoVinculado()" />
                                <label class="form-check-label" for="acordo_avulso">Acordo avulso</label>
                            </div>
                        </div>
                        <div class="form-group" style="width: 100%;" *ngIf="camposSimulacao.tipoAcordo == '0'">
                            <label class="form-label">Acordos vinculados</label>
                            <br />
                            <div class="form-check form-check-inline form-group mt-1">
                                <input mdbRadio name="tipoValor" class="form-check-input" type="radio"
                                    [(ngModel)]="camposSimulacao.acordoVinculado" id="valor_divida" value="0" />
                                <label class="form-check-label" for="valor_divida">Dívidas</label>
                            </div>
                            <div class="form-check form-check-inline form-group mt-1">
                                <input mdbRadio name="tipoValor" class="form-check-input" type="radio"
                                    [(ngModel)]="camposSimulacao.acordoVinculado" id="valor_atualizado" value="1" />
                                <label class="form-check-label" for="valor_atualizado">Valor atualizado</label>
                            </div>

                        </div>
                        <div class="form-group" style="width: 100%;" *ngIf="camposSimulacao.tipoAcordo == '0'">
                          <div class="form-check form-check-inline form-group mt-1">
                            <input mdbRadio name="tipoValor" class="form-check-input" type="radio"
                                [(ngModel)]="camposSimulacao.acordoVinculado" id="valor_causa" value="2" />
                            <label class="form-check-label" for="valor_causa">Valor da causa</label>
                        </div>
                        </div>
                        <div class="form-group" style="width: 100%;" *ngIf="camposSimulacao.tipoAcordo == '0'">
                            <label class="form-label" *ngIf="camposSimulacao.acordoVinculado == '0'">Valor da
                                dívida</label>
                            <div *ngIf="camposSimulacao.acordoVinculado == '0'">
                                R$ {{valorDivida.toFixed(2)}}
                            </div>
                            <label class="form-label" *ngIf="camposSimulacao.acordoVinculado == '1'">Valor
                                atualizado</label>
                            <div *ngIf="camposSimulacao.acordoVinculado == '1'">
                                R$ {{valorAtualizacaoValores.toFixed(2)}}
                            </div>
                            <label class="form-label" *ngIf="camposSimulacao.acordoVinculado == '2'">Valor
                                causa</label>
                            <div *ngIf="camposSimulacao.acordoVinculado == '2'">
                                R$ {{valorCausa.toFixed(2)}}

                            </div>
                        </div>
                        <div class="form-group" style="width: 100%;" *ngIf="camposSimulacao.tipoAcordo == '1'">
                            <label class="form-label">Valor para Quitação</label>
                            <input type="text" style="width: 150px;" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                                [(ngModel)]="camposSimulacao.valorAvulso" />
                        </div>
                        <div class="form-group" style="width: 100%;">
                            <label class="form-label">Data do inicio do parcelamento</label>
                            <br>
                            <p-calendar [(ngModel)]="camposSimulacao.dataInicialParcelas" dateFormat="dd/mm/yy"
                                slotChar="dd/mm/yyyy" [showIcon]="true" inputId="icon"></p-calendar>
                        </div>

                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div *ngIf="camposSimulacao.diaFixoOption == 'Sim'" class="form-group" style="width: 100%;">
                                <label class="form-label">N. parcelas</label>
                                <input type="number" [max]="maxParcelamento" [min]="minParcelamento" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacao.numParcelas">
                            </div>
                            <div *ngIf="camposSimulacao.diaFixoOption == 'Não'" class="form-group" style="width: 48%;">
                                <label class="form-label">N. parcelas</label>
                                <input type="number" [max]="maxParcelamento" [min]="minParcelamento" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacao.numParcelas">
                            </div>

                            <div *ngIf="camposSimulacao.diaFixoOption == 'Não'" class="form-group" style="width: 48%;">
                                <label class="form-label">Intervalo de dias</label>
                                <input type="number" max="300" min="0" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacao.intervaloDias">
                            </div>
                        </div>

                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">Dia fixo</label>
                                <select class="custom-select" [(ngModel)]="camposSimulacao.diaFixoOption">
                                    <option selected>Sim</option>
                                    <option>Não</option>
                                </select>
                            </div>



                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">Tipo Acordo Jurídico</label>
                                
                                <ng-select [items]="listTipoAcordo" [(ngModel)]="camposSimulacao.tipo"
                                    [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                    placeholder="Tipo de Acordo Jurídico" [loading]="listTipoAcordo == undefined || listTipoAcordo.length == 0">
                                </ng-select>                                  
                            </div>

                        </div>
                        <div class="form-row mb-3">
                          <label class="form-label ">Em caso descumprimento de acordo</label>
                        </div>                        
                        <div style="display: flex;flex-direction: row;justify-content: space-between;">
                            <div class="form-group" style="width: 100%;">
                                <label class="form-label">Selecione o índice de correção</label>
                                <ng-select [items]="indices" [(ngModel)]="camposSimulacao.indice"
                                    [ngModelOptions]="{standalone: true}" bindLabel="nome" bindValue="id"
                                    placeholder="Índices" [loading]="indices == undefined || indices.length == 0"></ng-select>  
                            </div>  
                        </div>
                        <div style="display: flex;flex-direction: row;justify-content: space-between;">                                                                            
                          <div class="form-group">
                            <label class="form-label">% Honorários advocatícios de descumprimento</label>
                            <input type="text" style="width: 50%;" max="100" min="0" class="form-control" placeholder=""
                                currencyMask [options]="{ prefix:'', suffix: ' %', thousands: '.', decimal: ',' }"
                                [(ngModel)]="camposSimulacao.percentualHonorarioAdvocaticioDescumprimento">
                          </div>                          
                        </div>
                        <div style="display: flex;flex-direction: row;justify-content: space-between;">
                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">% Juros</label>
                                <input type="text" max="100" min="0" class="form-control" placeholder=""
                                    currencyMask [options]="{ prefix:'', suffix: ' %', thousands: '.', decimal: ',' }"
                                    [(ngModel)]="camposSimulacao.percentualJuros">
                            </div>
                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">% Multa</label>
                                <input type="text" max="100" min="0" class="form-control" placeholder=""
                                    currencyMask [options]="{ prefix:'', suffix: ' %', thousands: '.', decimal: ',' }"
                                    [(ngModel)]="camposSimulacao.percentualMulta">
                        </div>

                        </div>
<!--
                        <div style="display: flex;flex-direction: row;justify-content: space-between;">


                            <div class="form-group" style="width: 48%;">
                                <label class="form-label" style="color: white;">-</label>
                                <select class="custom-select" [(ngModel)]="camposSimulacao.acrescimoOuDesconto"
                                    disabled>
                                    <option selected>Desconto</option>
                                    <option>Acréscimo</option>
                                </select>
                            </div>

                            <div class="form-group"
                                style="width: 48%;height: 30px;display: flex;flex-direction: row;justify-content: flex-end;">


                                <div class="form-group" style="width: 42%;">
                                    <label class="form-label" style="color: white;">-</label>
                                    <input type="number" max="300" min="0" class="form-control" placeholder=""
                                        [(ngModel)]="camposSimulacao.porcentagemOuValor" disabled>
                                </div>


                                <div class="form-group" style="width: 54%;">
                                    <label class="form-label" style="color: white;">-</label>
                                    <select class="custom-select" [(ngModel)]="camposSimulacao.porcentagemOuValorOption"
                                        disabled>
                                        <option selected>%</option>
                                        <option>R$</option>
                                    </select>
                                </div>

                            </div>


                        </div>


                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">Perc Juros
                                    Processo</label>
                                <input type="number" max="300" min="0" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacao.percentualJuros">
                            </div>


                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">Perc
                                    administrativa</label>
                                <input type="number" max="300" min="0" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacao.percentualTaxaAdministrativa">
                            </div>

                        </div>

-->
                    </div>
                </div>
            </div>
            <div [ngClass]="(tipoAcordo == 0 ? 'col-6' : 'col-12')" *ngIf="tipoAcordo == 0 || tipoAcordo == 2">
                <div class="borda card">
                    <h3>Honorários Advocatícios</h3>
                    <div>
                        <div class="form-group" style="width: 100%;">
                            <label class="form-label">Valor honorário</label>
                            <input type="text" style="width: 150px;" currencyMask
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                                [(ngModel)]="camposSimulacaoHonorario.valorAvulso" />
                        </div>
                        <div class="form-group" style="width: 100%;">
                            <label class="form-label">Data do inicio do parcelamento</label>
                            <br>
                            <p-calendar [(ngModel)]="camposSimulacaoHonorario.dataInicialParcelas" dateFormat="dd/mm/yy"
                                slotChar="dd/mm/yyyy" [showIcon]="true" inputId="icon"></p-calendar>
                        </div>

                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div *ngIf="camposSimulacaoHonorario.diaFixoOption == 'Sim'" class="form-group"
                                style="width: 100%;">
                                <label class="form-label">N. parcelas</label>
                                <input type="number" [max]="maxParcelamento" [min]="minParcelamento" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacaoHonorario.numParcelas">
                            </div>
                            <div *ngIf="camposSimulacaoHonorario.diaFixoOption == 'Não'" class="form-group"
                                style="width: 48%;">
                                <label class="form-label">N. parcelas</label>
                                <input type="number" [max]="maxParcelamento" [min]="minParcelamento" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacaoHonorario.numParcelas">
                            </div>

                            <div *ngIf="camposSimulacaoHonorario.diaFixoOption == 'Não'" class="form-group"
                                style="width: 48%;">
                                <label class="form-label">Intervalo de dias</label>
                                <input type="number" max="300" min="0" class="form-control" placeholder=""
                                    [(ngModel)]="camposSimulacaoHonorario.intervaloDias">
                            </div>
                        </div>

                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div class="form-group" style="width: 48%;">
                                <label class="form-label">Dia fixo</label>
                                <select class="custom-select" [(ngModel)]="camposSimulacaoHonorario.diaFixoOption">
                                    <option selected>Sim</option>
                                    <option>Não</option>
                                </select>
                            </div>                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="parcelas.length > 0 || parcelasHonorario.length > 0">
            <div class="col-12" *ngIf="parcelas.length > 0 && (tipoAcordo == 0 || tipoAcordo == 1)">
                <div style="display: flex;flex-direction: column;width: 100%;margin-top: 15px;">

                    <div class="col-md-12  scroll-bar-padrao" style="overflow-x:auto ;">
                        <h3>Valor Principal</h3>
                        <table class="table product-discounts-edit espacamento-tabela-dividas">
                            <thead>
                                <tr>
                                    <th>N. Documento</th>
                                    <th>Tipo Parcela</th>
                                    <th>Emissão</th>
                                    <th width="100px">Vencimento</th>
                                    <th>Valor Principal</th>
                                    <th *ngIf="parcelas[0].tipoAcordo != '1'">Juros</th>
                                    <th *ngIf="parcelas[0].tipoAcordo != '1'">Multa</th>
                                    <th *ngIf="parcelas[0].tipoAcordo != '1'">Custas</th>
                                    <th *ngIf="parcelas[0].tipoAcordo != '1'">Atualização Custas</th>
                                    <th>Total</th>
                                    <th>Fixo</th>
                                </tr>
                            </thead>
                            <tbody style="font-size: 8pt">
                                <tr *ngFor="let parcela of parcelas; let i = index">
                                    <td>{{parcela.numDoc}}</td>
                                    <td>Acordo Principal</td>
                                    <td>{{parcela.emissao}}</td>                                    
                                    <td>
                                        <p-calendar appendTo="body" baseZIndex = 1050 [style]="{'width':'120px'}" [(ngModel)]="parcela.vencimento" dateFormat="dd/mm/yy"
                                        slotChar="dd/mm/yyyy" [showIcon]="true" inputId="icon"></p-calendar>
                                    </td>
                                    <td>{{parcela.valorAberto2 | currency: 'BRL'}}</td>
                                    <td *ngIf="parcela.tipoAcordo != '1'">{{parcela.juros | currency: 'BRL'}}</td>
                                    <td *ngIf="parcela.tipoAcordo != '1'">{{parcela.multa | currency: 'BRL'}}</td>
                                    <td *ngIf="parcela.tipoAcordo != '1'">{{parcela.custas | currency: 'BRL'}}</td>
                                    <td *ngIf="parcela.tipoAcordo != '1'">{{parcela.atualizacaoCusta | currency: 'BRL'}}</td>
                                    <td>
                                        <div>
                                            <input type="text" style="width: 150px;" currencyMask
                                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                                                [(ngModel)]="parcela.valorTotal2" (blur)="calcularValorTotalAcordo(parcela)" />
                                        </div>
                                    </td>
                                    <td>
                                        <input class="form-check-input ml-2 mt-2" type="checkbox" (change)="calcularValorTotalAcordo(parcela)" [(ngModel)]="parcela.alterado">
                                      </td>
                            </tbody>
                            <tfoot>
                                <tr style="background-color: rgb(224, 224, 224);">
                                    <td style="text-align: right;" colspan="5" *ngIf="parcelas[0].tipoAcordo == '1'">
                                        <b>Total</b>
                                    </td>
                                    <td style="text-align: right;" colspan="9" *ngIf="parcelas[0].tipoAcordo != '1'">
                                        <b>Total</b>
                                    </td>                                    
                                    <td>{{valorTotalAcordo |
                                        currency: 'BRL' }}</td>                                    
                                </tr>
                            </tfoot>
                        </table>
                    </div>



                    <table class="table product-discounts-edit espacamento-tabela-dividas" *ngIf="parcelas[0].tipoAcordo != '1'">
                        <thead>
                            <tr>
                                <th>Valor cobrança</th>
                                <th>Juros</th>
                                <th>Multa</th>
                                <th>Custas</th>
                                <th>Atualização de custas</th>
                                <th>Total cobrança</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 8pt">
                            <tr>
                                <td>{{acordos[0].totalEmAberto | currency: 'BRL'}}</td>
                                <td>{{acordos[0].juros | currency: 'BRL'}}</td>
                                <td>{{acordos[0].multa | currency: 'BRL'}}</td>
                                <td>{{acordos[0].custas | currency: 'BRL'}}</td>
                                <td>{{acordos[0].atualizacaoCusta | currency: 'BRL'}}</td>
                                <td>{{(acordos[0].totalEmAberto + acordos[0].juros + acordos[0].multa +
                                    acordos[0].custas + acordos[0].atualizacaoCusta) | currency: 'BRL'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class="col-12" *ngIf="parcelasHonorario.length > 0 && (tipoAcordo == 0 || tipoAcordo == 2)">
                <div style="display: flex;flex-direction: column;width: 100%;margin-top: 15px;">

                    <div class="col-md-12  scroll-bar-padrao" style="overflow:auto ;">
                        <h3>Honorários Advocatícios</h3>
                        <table class="table product-discounts-edit espacamento-tabela-dividas">
                            <thead>
                                <tr>
                                    <th>N. Documento</th>
                                    <th>Tipo Parcela</th>
                                    <th>Emissão</th>
                                    <th>Vencimento</th>
                                    <th>Valor Principal</th>
                                    <th *ngIf="parcelasHonorario[0].tipoAcordo != '1'">Juros</th>
                                    <th *ngIf="parcelasHonorario[0].tipoAcordo != '1'">Multa</th>
                                    <th *ngIf="parcelasHonorario[0].tipoAcordo != '1'">Custas</th>
                                    <th *ngIf="parcelasHonorario[0].tipoAcordo != '1'">Atualização Custas</th>
                                    <th>Total</th>
                                    <th>Fixo</th>
                                    <!--th>Lançamento</th>
                                    <th>Tipo</th-->
                                </tr>
                            </thead>
                            <tbody style="font-size: 8pt">
                                <tr *ngFor="let parcela of parcelasHonorario; let i = index">
                                    <td>{{parcela.numDoc}}</td>
                                    <td>{{parcela.tipoParcela}}</td>
                                    <td>{{parcela.emissao}}</td>
                                    <td>
                                        <p-calendar appendTo="body" baseZIndex = 1050 [style]="{'width':'120px'}" [(ngModel)]="parcela.vencimento" dateFormat="dd/mm/yy"
                                        slotChar="dd/mm/yyyy" [showIcon]="true" inputId="icon"></p-calendar>
                                    </td>
                                    <td>{{parcela.valorAberto2 | currency: 'BRL'}}</td>
                                    <td  *ngIf="parcela.tipoAcordo != '1'">{{parcela.juros | currency: 'BRL'}}</td>
                                    <td  *ngIf="parcela.tipoAcordo != '1'">{{parcela.multa | currency: 'BRL'}}</td>
                                    <td  *ngIf="parcela.tipoAcordo != '1'">{{parcela.custas | currency: 'BRL'}}</td>
                                    <td  *ngIf="parcela.tipoAcordo != '1'">{{parcela.atualizacaoCusta | currency: 'BRL'}}</td>
                                    <td>
                                        <div>
                                            <input type="text" style="width: 150px;" currencyMask
                                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control"
                                                [(ngModel)]="parcela.valorTotal2" (blur)="calcularValorTotalHonorario(parcela)" />
                                        </div>
                                        <!-- <div *ngIf="parcela.tipoParcela != 'parcela normal'">
                                            {{parcela.valorTotal2 | currency: 'BRL'}}
                                        </div> -->
                                    </td>
                                    <td><input class="form-check-input ml-2 mt-2" type="checkbox" (change)="calcularValorTotalHonorario(parcela)" [(ngModel)]="parcela.alterado"></td>
                                    <!--td>{{parcela.lancamento}}</td>
                                    <td>{{parcela.tipoNome}}</td-->
                            </tbody>
                            <tfoot>
                                <tr style="background-color: rgb(224, 224, 224);">
                                    <td style="text-align: right;" colspan="5" *ngIf="parcelas[0].tipoAcordo == '1'">
                                        <b>Total</b>
                                    </td>
                                    <td style="text-align: right;" colspan="9" *ngIf="parcelas[0].tipoAcordo != '1'">
                                        <b>Total</b>
                                    </td>                                    
                                    <td>{{valorTotalAcordoHonorario |
                                        currency: 'BRL' }}</td>                                    
                                </tr>
                            </tfoot>
                        </table>
                    </div>



                    <table class="table product-discounts-edit espacamento-tabela-dividas"  *ngIf="parcelasHonorario[0].tipoAcordo != '1'">
                        <thead>
                            <tr>
                                <th>Valor cobrança</th>
                                <th>Juros</th>
                                <th>Multa</th>
                                <th>Custas</th>
                                <th>Atualização de custas</th>
                                <th>Total cobrança</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 8pt">
                            <tr>
                                <td>{{acordos[1].totalEmAberto | currency: 'BRL'}}</td>
                                <td>{{acordos[1].juros | currency: 'BRL'}}</td>
                                <td>{{acordos[1].multa | currency: 'BRL'}}</td>
                                <td>{{acordos[1].custas | currency: 'BRL'}}</td>
                                <td>{{acordos[1].atualizacaoCusta | currency: 'BRL'}}</td>
                                <td>{{(acordos[1].totalEmAberto + acordos[1].juros + acordos[1].multa +
                                    acordos[1].custas + acordos[1].atualizacaoCusta) | currency: 'BRL'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-success btn-calcular" *ngIf="parcelas.length == 0 && parcelasHonorario.length == 0" [disabled]="disabledBotaoCalcular()" (click)="calcularParcelas()"> Calcular</button>
        <button type="button" class="btn btn-danger"  (click)="limparParcelas()" *ngIf="parcelas.length > 0 && parcelasHonorario.length>0"><i class="fas fa-trash"></i> Limpar Parcelas</button>
        <button type="button" class="btn btn-primary" (click)="salvar(false)" *ngIf="!salvarBtnDisable"><i class="fas fa-save"></i> Salvar em simulações</button>
        <button type="button" class="btn btn-success" (click)="salvar(true)" *ngIf="!salvarBtnDisable"><i class="fas fa-save"></i> Salvar Acordo e Aprovar</button>
    </div>
</div>
