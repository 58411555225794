import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { ModalPagamentoComponent } from '../modal-pagamento/modal-pagamento.component';

@Component({
  selector: 'app-modal-parcelas',
  templateUrl: './modal-parcelas.component.html',
  styleUrls: ['./modal-parcelas.component.scss']
})
export class ModalParcelasComponent implements OnInit {

    private modalRef: NgbModalRef;

    private acordo: any;
    private acordoHonorario: any;

    private acordoId: string;
    private acordoIdHonorario: string;

    public loadingAcordo: boolean = false;
    public loadingAcordoHonorario: boolean = false;

    public raiz: string = '';

    constructor(
        private acordoJuridicoService: AcordoJuridicoService,
        public toastrService: ToastrService,
        public modalService: NgbModal,
        private arquivoService: ArquivoService,
        public utilsService: UtilsService,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }

        this.carregarAcordo();
        
        //this.carregarAcordoHonorario();
    }
    carregarAcordoHonorario() {
      this.loadingAcordoHonorario = true;

      this.acordoJuridicoService.obterPorId(this.acordoIdHonorario).subscribe(res=>{
          this.acordoHonorario = res.data;
          this.loadingAcordoHonorario = false;
          console.log(res.data);
      }, error => {
          if (error && error.errors) {
              this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
          }
          else {
              this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
          }
          this.loadingAcordoHonorario = false;
      });
  }

    carregarAcordo() {
        this.loadingAcordo = true;

        this.acordoJuridicoService.obterPorId(this.acordoId).subscribe(res=>{
            this.acordo = res.data;
            this.loadingAcordo = false;
            console.log(res.data);
        }, error => {
            if (error && error.errors) {
                this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
            }
            else {
                this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
            }
            this.loadingAcordo = false;
        });
    }

    abrirModalPagamentoAcordo(acordo, parcela){

        this.acordoJuridicoService.checarParcelaPossuiSuccessFeeParaAtender(parcela.id)
            .subscribe(res=>{
                if(res.data){
                    this.showModalPagamento(acordo, parcela);
                }else{
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-padrao btn-lg mr-3',
                            cancelButton: 'btn btn-default btn-lg'
                        },
                        buttonsStyling: false
                    });
            
                    swalWithBootstrapButtons.fire({
                        title: 'Não foram encontrados dados de faturamento do tipo Success Fee para o valor da parcela informada, deseja incluir agora?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {                                 
                            this.acordoJuridicoService.obterChaveProcessoPorParcela(parcela.id)
                                .subscribe(res=>{                                                                        
                                    const url = this.raiz + '/processos-judiciais/editar/' + res.data;
                                    
                                    window.open(url, '_blank');

                                }, err=>{
                                    this.toastrService.error('Não foi possível carregar o processo!', 'Atenção', { timeOut: 10000 });
                                })
                        }
                    });
                }
            },err=>{
                this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
            })



        
    }

    showModalPagamento(acordo, parcela){
        
        const modalRef = this.modalService.open(ModalPagamentoComponent, {
            size: "lg",
            backdrop : 'static',
            keyboard : false
        });

        modalRef.componentInstance.parcela = parcela;
        modalRef.componentInstance.acodo = acordo;
        modalRef.componentInstance.acordo = this.acordo;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result
        .then((result) => {
            this.carregarAcordo();
        });
    }

    downloadAnexo(acordo){        
        this.arquivoService.downloadArquivo(acordo.arquivoId).subscribe(data=>{
            this.utilsService.renderDownload(data, acordo.arquivoNome as string);            
        },err=>{            
            this.toastrService.error('Não foi possível fazer o download!', 'Atenção', { timeOut: 10000 });
        })
    }

    dismiss(){
        this.modalRef.dismiss();
    }

    close(){
        this.modalRef.close();
    }

}
